import React, { useContext } from 'react';

import styles from './styles.module.scss';
import { HomePageContext } from '../../context';

const LinkFarm = () => {
    const { data } = useContext(HomePageContext);
    if (!data?.city_links) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Where is Wedding Spot?</div>
            <div className={styles.links}>
                {data.city_links.map((link) => (
                    <div className={styles.link}>
                        <a href={link.url}>{link.label}</a>
                    </div>
                ))}
            </div>
            <div className={styles.footer}>
                <a href={'/locations/'}>See all regions</a>
            </div>
        </div>
    );
};

export default LinkFarm;
