import React from 'react';

import styles from './styles.module.scss';
import Section from '../index';

const Box = (props: { title: string; copy: string; iconStyle: string }) => {
    return (
        <div className={styles.box}>
            <span className={props.iconStyle} />
            <div className={styles.title}>{props.title}</div>
            <div className={styles.copy}>{props.copy}</div>
            <a href={'/how-it-works/'}>
                <b>Learn More &#0187;</b>
            </a>
        </div>
    );
};

const HowItWorksSection = () => {
    return (
        <Section title={'Find a Wedding Venue: How It Works'} headingNumber={2}>
            <div className={styles.wrapper}>
                <Box
                    title={'Search'}
                    copy={'Find your perfect wedding venue without all the stress. Search by location, budget, styles, and capacity.'}
                    iconStyle={styles.iconSearch}
                />
                <Box
                    title={'Price'}
                    copy={'Build out your ideal wedding and get a budget estimate based on your guest count and selected options.'}
                    iconStyle={styles.iconPricetag}
                />
                <Box
                    title={'Discounts'}
                    copy={'Get access to exclusive deals and discounts and save money on venue fees.'}
                    iconStyle={styles.iconSave}
                />
            </div>
        </Section>
    );
};

export default HowItWorksSection;
