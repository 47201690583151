import React from 'react';

import Section from './../index';
import { Home } from '@weddingspot/ws-api-client';

import styles from './styles.module.scss';
import LocationTile from './LocationTile';
import { TABLET_SCREEN_SIZE, useWiderThan } from '@weddingspot/ws-core-components';
import cn from 'classnames';
import Carousel from '@cvent/carina/components/Carousel';

const LocationsSection = (
    props: React.PropsWithChildren<{
        title: string;
        titleUrl?: string;
        footer?: string;
        footerUrl?: string;
        locations: Home.Location[];
        columns: number;
        headingNumber?: number;
        showRoundedTile?: boolean;
        showInCarousel?:boolean;
        contentWrap?: boolean;
    }>
) => {
    const isDesktopOrTablet = useWiderThan(TABLET_SCREEN_SIZE-1);
    const columns = isDesktopOrTablet ? props.columns : 1;
    let cells = 0;

    return (
        <Section title={props.title} titleUrl={props.titleUrl} headingNumber={props.headingNumber}>            
            {props.showInCarousel ? (

                <div id={styles.carouselContainer}>
                    <Carousel hideDots cols={isDesktopOrTablet ? 3 : 2} nextAccessibilityLabel='next' previousAccessibilityLabel='previous'>
                        {props.locations.map((loc, idx) => {
                            return <LocationTile key={idx} row={null} section={props.title} {...loc} />;
                        })}
                    </Carousel>
                </div>
            ) : (
                <div
                className={cn(styles.wrapper, styles.one, {
                    [styles.wrapper_rounded]: props.showRoundedTile
                })}
                style={{
                    gridTemplateColumns: `repeat(${columns}, 1fr)`,
                }}
            >
                {props.locations.map((loc, idx) => {
                    let cell = 1;
                    if (isDesktopOrTablet && loc.image_url_path_landscape) {
                        cell = 2;
                    }
                    if (isDesktopOrTablet && loc.image_url_path_banner) {
                        cell = 3;
                    }
                    cells += cell;
                    return <LocationTile showRoundedTile={props.showRoundedTile} contentWrap={props.contentWrap} key={idx} row={Math.ceil(cells / columns)} section={props.title} {...loc} />;
                })}
            </div>
            )
}

            {props.footer && props.footerUrl && (
                <div className={styles.footer}>
                    <a href={props.footerUrl}>{props.footer}</a>
                </div>
            )}
        </Section>
    );
};

export default LocationsSection;
