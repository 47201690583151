import { Home } from '@weddingspot/ws-api-client';
import cn from 'classnames';
import styles from './LocationTile.module.scss';
import React from 'react';
import { TABLET_SCREEN_SIZE, useWiderThan } from '@weddingspot/ws-core-components';
import { AnalyticsEvent, logEvent } from '@weddingspot/ws-analytics';
import { HOGAN_CONFIG } from 'configs/appconfig';
import Image from 'next/image';

const LocationTile = (
    props: Home.Location & {
        row: number;
        section: string;
        showRoundedTile?: boolean;
        contentWrap?: boolean;
    }
) => {
    const isDesktopOrTablet = useWiderThan(TABLET_SCREEN_SIZE-1);
    return (
        <div
            className={cn(styles.wrapper, styles.one, {
                [styles.three]: isDesktopOrTablet && props.image_url_path_banner,
                [styles.two]: isDesktopOrTablet && props.image_url_path_landscape,
                [styles.wrapper_rounded]: props.showRoundedTile,
                [styles.wrapper_image]: !props.showRoundedTile,
            })}
        >
            <a
                href={props.link_url_path}
                onClick={() => {
                    logEvent(AnalyticsEvent.EVTNM_CLICKED_LOCATION_SUGGESTIONS, {
                        Source: props.section,
                        Position: `row ${props.row}`,
                    });
                }}
            >
                <div className={cn(styles.image, { [styles.wrapper_rounded]: props.showRoundedTile })} style={{ position: 'relative' }}>
                    <Image
                        src={
                            HOGAN_CONFIG.cdn + '/' +
                            (isDesktopOrTablet
                                ? props.image_url_path_banner || props.image_url_path_landscape || props.image_url_path
                                : props.image_url_path)
                        }
                        alt={props.title}
                        layout='fill'
                        unoptimized={true}
                    />
                </div>
                <div className={cn(styles.title, {
                    [styles.title_in_rounded_wrapper]:props.showRoundedTile,
                    [styles.title_in_contentWrapper]:props.contentWrap
                })}>{props.title}</div>
            </a>
        </div>
    );
};

export default LocationTile;
