import React from 'react';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { Home } from '@weddingspot/ws-api-client/src/types/home';
import { HomeApi } from '@weddingspot/ws-api-client';
import { URL } from 'url';
import { HomePageContextProvider } from '../components/home/context';
import { VenueProfileProps } from '../components/venueProfile/types';
import HomePage from '../components/home';

export interface HomePageProps {
    notFound: boolean;
    data?: Home.HomepageData;
}

export default ({ data }: HomePageProps) => (
    <HomePageContextProvider data={data}>
        <HomePage />
    </HomePageContextProvider>
);

export const getServerSideProps: GetServerSideProps<VenueProfileProps> = async (context: GetServerSidePropsContext) => {
    try {
        const data = await HomeApi.getData();
        if (data.hero?.next_is_off) {
            const url = new URL(context.req.url || '', `https://${context.req.headers.host}`);
            url.searchParams.set('forceversion', 'v1');
            return {
                redirect: {
                    destination: url.pathname + url.search,
                    permanent: false,
                },
            };
        }
        return {
            props: {
                data,
                notFound: false,
            },
        };
    } catch (err) {
        console.error('Home page server side error', err);
        return { notFound: true };
    }
};
