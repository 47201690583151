import React, { useContext } from 'react';

import LocationsSection from '../locations-section';
import { HomePageContext } from '../../context';

const StylesSection = () => {
    const { data } = useContext(HomePageContext);
    if (!data?.styles) {
        return null;
    }

    return (
        <LocationsSection
            title={'Explore Wedding Venue Styles'}
            footer={'View all Styles'}
            footerUrl={'/wedding-venue-styles/'}
            locations={data.styles}
            showRoundedTile={true}
            //NOTE: To display Wedding Venue Styles, we are using css display:'flex'. Column value wont make any difference here. 
            columns={4}
            headingNumber={3}
        />
    );
};

export default StylesSection;
