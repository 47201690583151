import React, { useContext } from 'react';

import LocationsSection from '../locations-section';
import { HomePageContext } from '../../context';

const RDSection = () => {
    const { data } = useContext(HomePageContext);
    if (!data?.rd) {
        return null;
    }

    return <LocationsSection title={'Search For Your Ideal Rehearsal Dinner'} locations={data.rd} columns={2} />;
};

export default RDSection;
