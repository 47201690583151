import React, { useContext } from 'react';

import LocationsSection from '../locations-section';
import { HomePageContext } from '../../context';

const FeaturedSection = () => {
    const { data } = useContext(HomePageContext);
    if (!data?.featured) {
        return null;
    }

    return <LocationsSection title={'Plan Your Dream Wedding'} locations={data.featured} columns={3} headingNumber={2}/>;
};

export default FeaturedSection;
