import React, { useEffect } from 'react';

import styles from './styles.module.scss';

import HeroSection from './sections/hero';
import AwardsSection from './sections/awards';
import HowItWorksSection from './sections/how-it-works';
import FeaturedSection from './sections/featured';
import AffordableSection from './sections/affordable';
import StylesSection from './sections/styles';
import DestinationSection from './sections/destination';
import BestWeddingVenuesSection from './sections/best-wedding-venues';
import RDSection from './sections/rd';
import LinkFarm from './sections/link-farm';
import { getAssetPrefix, useWiderThan } from '@weddingspot/ws-core-components';
import Head from 'next/head';
import { AnalyticsEvent, logEvent } from '@weddingspot/ws-analytics';

const HomePage = () => {
    const isDesktop = useWiderThan(1200);

    useEffect(() => {
        logEvent(AnalyticsEvent.EVTNM_VISITED_WEDDING_SPOT_HOME_PAGE, {
            Version: 'next',
        });
    }, []);

    return (
        <>
            <Head>
                <title>Wedding Venues &amp; Planning Tools | Wedding Spot </title>
                <meta
                    name='description'
                    content='Wedding Spot is the first online site that allows you to search, price out, and compare wedding venues. Get started here.'
                />
                <meta
                    name='keywords'
                    content='Wedding Spot, affordable venues, wedding venues, bay area weddings, wedding budget, wedding prices, wedding costs'
                />
                <link rel='canonical' href='/' />
                <link rel="preconnect" href={`${getAssetPrefix()}`} />
            </Head>
            <div className={styles.wrapper}>
                <HeroSection />
                <div className={styles.inner}>
                    <AwardsSection />
                    {isDesktop && <HowItWorksSection />}
                    <FeaturedSection />
                    <AffordableSection />
                    <StylesSection />
                    <DestinationSection />
                    <BestWeddingVenuesSection />
                    <RDSection />
                </div>
                {<LinkFarm />}
                {/**/}
            </div>
        </>
    );
};

export default HomePage;
