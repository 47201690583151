import React from 'react';

import styles from './styles.module.scss';

let CustomHeadingTag: keyof JSX.IntrinsicElements;
    
const Section = (
    props: React.PropsWithChildren<{
        title: string;
        titleUrl?: string;
        headingNumber?: number;
    }>
) => {

    if(props.headingNumber){
        CustomHeadingTag = `h${props.headingNumber}` as keyof JSX.IntrinsicElements;
    }else{
        CustomHeadingTag = `div` as keyof JSX.IntrinsicElements;
    }
     
    return (
        <div className={styles.wrapper}>
            <CustomHeadingTag className={styles.title}>{props.titleUrl ? <a href={props.titleUrl}>{props.title}</a> : props.title}</CustomHeadingTag>
            <div className={styles.inner}>{props.children}</div>
        </div>
    );
};

export default Section;
