import React, { createContext, PropsWithChildren } from 'react';
import { Home } from '@weddingspot/ws-api-client';

export interface HomePageContextInterface {
    data?: Home.HomepageData;
}

export const HomePageContext = createContext<HomePageContextInterface>({});

export const HomePageContextProvider = <P extends HomePageContextInterface>(props: PropsWithChildren<P>) => {
    const { data } = props;

    return <HomePageContext.Provider value={{ data }}>{props.children}</HomePageContext.Provider>;
};
