import React, { useContext } from 'react';

import LocationsSection from '../locations-section';
import { HomePageContext } from '../../context';

const BestWeddingVenuesSection = () => {
    const { data } = useContext(HomePageContext);
    if (!data?.best_weddings) {
        return null;
    }

    return (
        <LocationsSection
            title={'Best Wedding Venues'}
            locations={data.best_weddings}
            columns={2}
            headingNumber={2}
            contentWrap={true}
        />
    );
};

export default BestWeddingVenuesSection;