import React, { useContext } from 'react';

import LocationsSection from '../locations-section';
import { HomePageContext } from '../../context';

const AffordableSection = () => {
    const { data } = useContext(HomePageContext);
    if (!data?.affordable) {
        return null;
    }

    return (
        <LocationsSection
            title={'Looking For Affordable Wedding Venues?'}
            titleUrl={'/affordable-wedding-venues/'}
            footer={'See all curated affordable venues'}
            footerUrl={'/affordable-locations/'}
            locations={data.affordable}
            columns={2}
            headingNumber={3}
            showInCarousel={true}
        />
    );
};

export default AffordableSection;
