import React from 'react';

const useTimer = (initialState = 0) => {
    const [timer, setTimer] = React.useState(initialState);
    const [isActive, setIsActive] = React.useState(false);
    const [isPaused, setIsPaused] = React.useState(false);
    const countRef = React.useRef(null);

    const handleStart = () => {
        setIsActive(true);
        setIsPaused(true);
        countRef.current = setInterval(() => {
            setTimer((val) => val + 1);
        }, 100);
    };

    const handlePause = () => {
        clearInterval(countRef.current);
        setIsPaused(false);
    };

    const handleResume = () => {
        setIsPaused(true);
        countRef.current = setInterval(() => {
            setTimer((val) => val + 1);
        }, 1000);
    };

    const handleReset = () => {
        clearInterval(countRef.current);
        setTimer(0);
        handleStart();
    };

    return { handlePause, handleReset, handleResume, handleStart, isActive, isPaused, timer };
};

export default useTimer;
