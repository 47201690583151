import React, { useCallback, useEffect, useRef, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import classNames from 'classnames';
import Link from 'next/link';
import { AnalyticsEvent, logEvent } from '@weddingspot/ws-analytics';
import { Typeahead, useWiderThan, getAssetPrefix } from '@weddingspot/ws-core-components';
import Button from '@cvent/carina/components/Button';
import Image from 'next/image';
import { FeatureFlagContext, FLAG_DW_HOMEPAGE_ADS } from '@weddingspot/ws-core-components';

import styles from './styles.module.scss';

import useTimer from '../../../../hooks/useTimer';

const localstorageIndexName = 'addnumbershow';

type DataListType = {
    title: string,
    description: string,
    location?: string,
    link: string,
    ctaText?: string
};

type AdsDataType = {
    imgLink: string;
    data: DataListType;
};

const HeroSection = () => {
    const { flagEnabled, isInitialized: isFlagsInitialized } = React.useContext(FeatureFlagContext);
    const [startIndex, setStartIndex] = useState<number>(-1);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [isInViewport, setIsInViewport] = useState(true);
    const [slidesList, setSlidesList] = useState<string[]>([]);
    const [dataList, setDataList] = useState<DataListType[]>([]);
    const imageGalleryContainerRef = useRef(null);
    const imageGalleryComponentRef = useRef(null);

    const adData: { [region: string]: AdsDataType } = {
        Cancun: {
            imgLink: `${getAssetPrefix()}/images/home/DestinationWeddings-Mexico.webp`,
            data: {
                title: 'Destination Weddings in Cancun, Mexico',
                description: `Bring your destination wedding vision to life with the perfect resort venue in Cancun, Riviera Maya, Tulum or beyond. Find the perfect place to say “I do” on a white-sand beach, in a lush garden gazebo or atop a panoramic rooftop terrace. Celebrate your love in paradise.`,
                link: 'wedding-venues/mexico/cancun?isdw=1',
                ctaText: 'View venues',
            },
        },
        Mexico: {
            imgLink: `${getAssetPrefix()}/images/home/DestinationWeddings-Mexico.webp`,
            data: {
                title: 'Destination Weddings in Mexico',
                description: `Bring your destination wedding vision to life with the perfect resort venue in Cancun, Cabo, Tulum or beyond. 
                Find the perfect place to say “I do” on a white-sand beach, in a lush garden gazebo or atop a panoramic rooftop terrace. Celebrate your love in paradise.`,
                link: 'wedding-venues/mexico/?isdw=1',
                ctaText: 'View venues',
            },
        },
        Jamaica: {
            imgLink: `${getAssetPrefix()}/images/home/DestinationWeddings-Jamaica.webp`,
            data: {
                title: 'Destination Weddings in Jamaica',
                description: `Bring your destination wedding vision to life with the perfect resort venue in Montego Bay, Negril or beyond. 
                Find the perfect place to say “I do” on a white-sand beach or lush garden gazebo. Celebrate your love in paradise.`,
                link: 'wedding-venues/jamaica/?isdw=1',
                ctaText: 'View venues',
            },
        },
        'Dominican Republic': {
            imgLink: `${getAssetPrefix()}/images/home/DestinationWeddings-DominicanRepublic.webp`,
            data: {
                title: 'Destination Weddings in the Dominican Republic',
                description: `Bring your destination wedding vision to life with the perfect resort venue in Punta Cana, La Romana, or beyond. 
                Find the perfect place to say “I do” on a white-sand beach or lush garden gazebo. Celebrate your love in paradise.`,
                link: 'wedding-venues/dominican-republic/?isdw=1',
                ctaText: 'View venues',
            },
        },
        'Caribbean Islands': {
            imgLink: `${getAssetPrefix()}/images/home/DestinationWeddings-CaribbeanIsland.webp`,
            data: {
                title: 'Best Wedding Venues in the Caribbean',
                description: `Aruba, Punta Cana, Jamaica and beyond – find your dream destination wedding venue and say “I do” with the Caribbean Sea 
                    as your backdrop. Stunning beaches, luxury venues and affordable packages.`,
                link: 'wedding-venues/caribbean-islands/?isdw=1',
                ctaText: 'View venues',
            },
        },
        'DR Punta Cana': {
            imgLink: `${getAssetPrefix()}/images/home/DestinationWeddings-DreamsRoyalBeachPuntaCana.webp`,
            data: {
                title: 'Top Destination Wedding Venue 2025',
                description: `Dreams Royal Beach Punta Cana is the perfect all-inclusive resort venue to exchange vows in paradise. Offering a variety of packages 
                    for every taste, style and budget, planning the wedding of your dreams has never been easier.`,
                link: 'venue/15769/dreams-royal-beach-punta-cana/',
                ctaText: 'View venue',
            },
        },
    };

    let slidesNum = dataList.length;

    const isDesktop = useWiderThan(768);
    const { timer, handleStart, handlePause, handleReset } = useTimer();

    useEffect(() => {
        if (isInViewport) {
            handleStart();
        } else {
            handlePause();
        }
    }, [isInViewport]);

    useEffect(() => {
        if (timer === 200) {
            handleReset();
            imageGalleryComponentRef.current.slideRight();
        }
    }, [timer, imageGalleryComponentRef.current]);

    useEffect(() => {
        if (isFlagsInitialized) {
            Object.keys(FLAG_DW_HOMEPAGE_ADS).forEach(function(region) {
                if (flagEnabled(FLAG_DW_HOMEPAGE_ADS[region])) {
                    setSlidesList((slidesList) => [...slidesList, adData[region]['imgLink']]);
                    setDataList((dataList) => [...dataList, adData[region]['data']]);
                }
            });

            slidesNum = slidesList.length;

            let localStorageValue = Number(
                localStorage.getItem(localstorageIndexName) || // start slider at next slide oppose to previous load
                    Math.floor(Math.random() * slidesNum) // start slider at random slide if this is the very first load
            );
            // Adding this check here to make sure the value is within the range of the slides
            // For e.g., if the Ads count is 2 at a point and the localStorageValue can be set to 0 or 1.
            // If the localStorageValue is set to 1, then the Ads count decreases to 1, it will throw an error due to Index out of Bounds
            // when accessing the dataList[1] in the below code, i.e., needed the following check.
            localStorageValue = localStorageValue >= 0 && localStorageValue < slidesNum ? localStorageValue : 0;

            setStartIndex(localStorageValue);
            //PROD-169711: Fixing name of the Venue is not the same as image on advertisement
            setCurrentIndex(localStorageValue);
            localStorage.setItem(localstorageIndexName, String((localStorageValue + 1) % slidesNum));
        }
    }, [isFlagsInitialized]);

    useEffect(() => {
        if (dataList && dataList[startIndex]) {
            logEvent(AnalyticsEvent.EVTNM_SHOWN_HERO_IMAGE_AD_PLACEMENT, {
                'venue name': dataList[startIndex].title,
            });
        }
    }, [startIndex]);

    useEffect(() => {
        const scrollListener = () => {
            const rect = imageGalleryContainerRef.current.getBoundingClientRect();
            setIsInViewport(rect.top + imageGalleryContainerRef.current.offsetHeight >= 0);
        };

        if (imageGalleryContainerRef.current) {
            document.addEventListener('scroll', scrollListener, {
                passive: true,
            });
        }
    }, [imageGalleryContainerRef.current]);

    const renderItem = (item) => {
        return (
            <div className={styles.slide}>
                <div className={styles.blur} style={{ backgroundImage: `url("${item}")` }} />
                <img alt='ballrom' src={item} />
            </div>
        );
    };

    const onBeforeSlideHandler = (nextIndex) => {
        setCurrentIndex(nextIndex);
    };

    const slideChangeHandler = () => {
        handleReset();
    };

    const logVenueClick = useCallback((adTitle) => {
        logEvent(AnalyticsEvent.EVTNM_CLICKED_VIEW_VENUE_CTA, {
            Source: 'Hero image',
            AdTitle: adTitle,
        });
    }, []);

    const goToWeddingVenues = () => {
        window.location.href = `${window.location.origin}/wedding-venues/`;
    };

    return (
        <div className={styles.wrapper}>
            <div ref={imageGalleryContainerRef}>
                {isFlagsInitialized && startIndex >= 0 ? (
                    <ImageGallery
                        additionalClass={classNames(styles.gallery, styles[`slide-${currentIndex}`])}
                        items={slidesList}
                        showThumbnails={false}
                        renderItem={renderItem}
                        showPlayButton={false}
                        showFullscreenButton={false}
                        autoPlay={false}
                        showBullets={isDesktop && slidesList.length > 1 }
                        startIndex={startIndex}
                        onSlide={slideChangeHandler}
                        onBeforeSlide={onBeforeSlideHandler}
                        ref={imageGalleryComponentRef}
                    />
                ) : (
                    <></>
                )}
            </div>
            <div className={styles.main}>
                <h1 className={styles.title}>Find Your Perfect Wedding Venue</h1>
                <div className={styles.input}>
                    <Typeahead />
                    <Button appearance='filled' text='Search' onClick={goToWeddingVenues} />
                </div>
            </div>
            {isFlagsInitialized && currentIndex < slidesNum ? (
                <div className={styles.footer}>
                    <div className={styles.venueInfo}>
                        <div className={styles.venueTitle}>
                            <div className={styles.venueName}>{dataList[currentIndex].title}</div>
                            <div className={styles.venueLocation}>{dataList[currentIndex].location}</div>
                        </div>
                        <div className={styles.venueDescription}>{dataList[currentIndex].description}</div>
                    </div>
                    <div className={styles.venueLink}>
                        <Link href={dataList[currentIndex].link} prefetch={false}>
                            <a onClick={() => {logVenueClick(dataList[currentIndex].title)}}>
                                <Button appearance='lined' text={!!dataList[currentIndex].ctaText? dataList[currentIndex].ctaText : 'View venue'} />
                            </a>
                        </Link>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default HeroSection;
