import React from 'react';

import styles from './styles.module.scss';
import { AnalyticsEvent, logEvent } from '@weddingspot/ws-analytics';

const AwardsSection = () => {
    return (
        <div className={styles.wrapper}>
            <h2 className={styles.title}>WEDDING SPOT AWARDS</h2>
            <div className={styles.copy}>
                From Rustic DIY to all-inclusive modern, our winners are the perfect example of a <br />
                best-in-class wedding experience. We love them. Couples love them. And you will too!
            </div>
            <div className={styles.link}>
                <a
                    href={'/awards/best-wedding-venues-2020/'}
                    onClick={() => {
                        logEvent(AnalyticsEvent.EVTNM_CLICKED_AWARD_BANNER, {
                            Award: '2020',
                        });
                    }}
                >
                    VIEW ANNUAL AWARDS WINNERS &raquo;
                </a>
            </div>
            <div className={styles.divider} />
        </div>
    );
};

export default AwardsSection;
