import React, { useContext } from 'react';

import LocationsSection from '../locations-section';
import { HomePageContext } from '../../context';

const DestinationSection = () => {
    const { data } = useContext(HomePageContext);
    if (!data?.destination) {
        return null;
    }

    return <LocationsSection title={'Plan Your Dream Destination Wedding'} locations={data.destination} columns={3} headingNumber={2}/>;
};

export default DestinationSection;
